<template>
  <v-container class="product-history">
    <div
      v-if="$vuetify.breakpoint.xs"
      class="back-buton-mobile"
      @click="$router.go(-1)"
    >
      <v-icon color="link" small>mdi-arrow-up-left</v-icon>
      {{ $t("product.history.backToProduct") }}
    </div>
    <h1 class="primary--text pt-6">
      {{ $t("product.history.historicalChanges") }}
    </h1>
    <div v-if="!$vuetify.breakpoint.xs" @click="$router.go(-1)" class="noPrint">
      <v-icon small>mdi-arrow-left</v-icon>
      <a class="link--text"> {{ $t("product.history.backToProduct") }}</a>
    </div>
    <div class="my-6"></div>
    <div class="custom-vertical-tab d-flex" v-if="!$vuetify.breakpoint.xs">
      <v-tabs
        vertical
        v-model="selectedTab"
        class="tabs-container-fix noPrint vertical-tabs"
      >
        <v-tabs-slider color="secondary"></v-tabs-slider>
        <v-tab
          @click="tabChaged(product)"
          v-for="(product, index) in products"
          :key="index"
          >{{ handleDate(product.versionDate) }}
          <span v-if="String(product.productId) === mainId" class="current">{{
            $t("common.current")
          }}</span>
        </v-tab>
      </v-tabs>
      <v-tabs-items
        vertical
        v-model="selectedTab"
        class="flex-grow-1 noPrint white-space-wrap"
      >
        <div
          v-if="selectedProduct && selectedProduct.pubState === 0 && showLink"
          @click="toProductDetails()"
          class="noPrint product-link"
        >
          <a class="link--text"> {{ $t("product.history.productDetails") }}</a>
          <v-icon small>mdi-arrow-right</v-icon>
        </div>
        <div v-for="(data, index) in json" :key="index">
          <h4 v-if="data.op" class="title">{{ $t(handleTitle(data.op)) }}</h4>
          <div v-if="data.from" class="from">
            <span>{{ $t("product.history.from") }}:</span>
            <p>{{ data.from }}</p>
            <span>{{ $t("product.history.to") }}:</span>
            <p v-if="data.path">{{ data.path }}</p>
          </div>
          <div v-if="data.path && !data.from" class="path">{{ data.path }}</div>
          <div v-if="data.fromValue" class="value">
            <span>{{ $t("product.history.oldValue") }}:</span>
            <p>{{ handleValue(data.fromValue) }}</p>
          </div>
          <div v-if="data.value" class="value">
            <span>{{ $t("product.history.newValue") }}:</span>
            <p>{{ handleValue(data.value) }}</p>
          </div>
        </div>
      </v-tabs-items>
    </div>
    <div v-else>
      <v-expansion-panels accordion flat>
        <v-expansion-panel
          v-for="(product, index) in products"
          :key="index"
          @change="tabChaged(product)"
        >
          <v-expansion-panel-header>
            <div class="d-flex justify-space-between">
              <div>{{ handleDate(product.versionDate) }}</div>
              <div v-if="String(product.productId) === mainId" class="current">
                {{ $t("common.current") }}
              </div>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div
              v-if="
                $vuetify.breakpoint.xs &&
                  selectedProduct &&
                  selectedProduct.pubState === 0 &&
                  showLink
              "
              class="back-buton-mobile"
              @click="toProductDetails()"
            >
              {{ $t("product.history.productDetails") }}
            </div>
            <div v-for="(data, index) in json" :key="index">
              <h4 v-if="data.op" class="title">
                {{ $t(handleTitle(data.op)) }}
              </h4>
              <div v-if="data.from" class="from">
                <span>{{ $t("product.history.from") }}:</span>
                <p>{{ data.from }}</p>
                <span>{{ $t("product.history.to") }}:</span>
                <p v-if="data.path">{{ data.path }}</p>
              </div>
              <div v-if="data.path && !data.from" class="path">
                {{ data.path }}
              </div>
              <div v-if="data.fromValue" class="value">
                <span>{{ $t("product.history.oldValue") }}:</span>
                <p>{{ handleValue(data.fromValue) }}</p>
              </div>
              <div v-if="data.value" class="value">
                <span>{{ $t("product.history.newValue") }}:</span>
                <p>{{ handleValue(data.value) }}</p>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </v-container>
</template>
<script>
import productService from "~/service/productService";
import ProductService from "~/service/productService";
export default {
  name: "ProductHistory",
  data() {
    return {
      selectedTab: null,
      products: [],
      selectedProduct: null,
      currentId: null,
      showLink: true,
      json: [],
      mainId: null
    };
  },
  methods: {
    handleTitle(engTitle) {
      let title = "";
      switch (engTitle) {
        case "replace":
          title = "product.history.replace";
          break;
        case "add":
          title = "product.history.add";
          break;
        case "remove":
          title = "product.history.remove";
          break;
        case "move":
          title = "product.history.move";
          break;
        default:
          title = engTitle;
      }
      return title;
    },
    handleDate(date) {
      date = date.replace(/ /g, "T");
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      // hh = "" + d.getHours(),
      // min = "" + d.getMinutes(),
      // sec = "" + d.getSeconds();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      // if (hh.length < 2) hh = "0" + hh;
      // if (min.length < 2) min = "0" + min;
      // if (sec.length < 2) sec = "0" + sec;

      return [day, month, year].join("/");
    },
    toProductDetails() {
      this.$router.push({
        name: "Product",
        params: { slug: this.selectedProduct.productId },
        query: { history: true }
      });
    },
    handleValue(value) {
      if (value.constructor.name == "Object") {
        return this.handleObject(value);
      }
      if (value.constructor.name == "Array") {
        return this.handleArray(value);
      }
      return value;
    },
    handleArray(array) {
      let value = "";
      array.forEach(element => {
        if (element.constructor.name == "Object") {
          value += this.handleObject(element);
        } else value += element + "\n";
      });
      return value;
    },
    handleObject(obj) {
      // return Object.entries(obj)
      //   .map(x => x.join(": "))
      //   .join("\n");
      return JSON.stringify(obj);
    },
    async tabChaged(value) {
      if (value.productId != this.currentId) {
        this.currentId = value.productId;
        this.json = [];
        this.showLink = false;
        let p = await productService.gethistoryProductsById(
          String(value.productId)
        );
        this.selectedProduct = p;
        this.showLink = true;
        if (
          p.metaData.product_diff_history &&
          p.metaData.product_diff_history.OLD_NEW_DATASHEET_COMPARE_RESULT
        ) {
          this.json =
            p.metaData.product_diff_history.OLD_NEW_DATASHEET_COMPARE_RESULT;
        } else this.json = [];
      }
    }
  },
  metaInfo() {
    return {
      title: this.$t("product.history.historicalChanges")
    };
  },
  async created() {
    this.mainId = this.$route.params.slug;
    var data = await ProductService.getProductsByMainId(
      this.$route.params.slug
    );
    if (data.productHistory && data.productHistory.length > 0) {
      this.products = data.productHistory;
      let p = await productService.gethistoryProductsById(
        String(data.productHistory[0].productId)
      );
      this.currentId = this.products[0].productId;
      this.selectedProduct = p;
      if (
        p.metaData.product_diff_history &&
        p.metaData.product_diff_history.OLD_NEW_DATASHEET_COMPARE_RESULT
      ) {
        this.json =
          p.metaData.product_diff_history.OLD_NEW_DATASHEET_COMPARE_RESULT;
      } else this.json = [];
    }
  }
};
</script>
<style lang="scss">
.product-history {
  .custom-vertical-tab .v-tabs {
    width: inherit;
  }
  .custom-vertical-tab .v-tab {
    min-width: 260px;
    max-width: 260px;
    justify-content: space-between !important;
  }
  .tabs-container-fix {
    flex-grow: 0;
    padding: 0 10px;
  }
  .bullets {
    white-space: pre-line;
  }
  .white-space-wrap {
    white-space: pre-line;
  }
  .path {
    word-wrap: break-word;
    margin-bottom: 15px;
    span {
      color: $secondary;
    }
  }
  .from {
    word-wrap: break-word;
    margin-bottom: 15px;
    span {
      color: $secondary;
    }
  }
  .title {
    margin-top: 0px !important;
  }
  .value {
    margin-bottom: 25px;
    white-space: pre-wrap;
    span {
      color: $secondary;
    }
  }
  .product-link {
    text-align: end;
    position: absolute;
    right: 0px;
    top: 3px;
  }
  .product-link-mobile {
    text-align: end;
  }
  .current {
    background: $primary;
    color: $white;
    padding: 3px 10px 3px 10px;
    margin-right: 5px;
    border-radius: 25px;
  }
}
</style>
